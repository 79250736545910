import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton, Typography, Button } from "@mui/joy";
import React, { useMemo, useRef, useState, FC } from "react";
import Slider from "react-slick";
import ImageViewer from "./ImageViewer";
import styled from "@emotion/styled";
import SectionHeader from "./SectionHeader";
import { useGetBlogPostsQuery } from "../apis/clubhub.api";
import LogoIcon from "../assets/Logo.svg";
import { useNavigate, useParams } from "react-router-dom";

interface NewsPostProps {
	title: string;
	image?: string;
	id: string;
}

export const NewsPost: FC<NewsPostProps> = ({ title, image, id }) => {
	const navigate = useNavigate();
	const venueName = useParams<{ venueName: string }>().venueName;

	const handleClick = () => {
		navigate(`/vu/${venueName}/blog/${id}`);
	};

	return (
		<Box
			sx={{
				display: "flex",
				justifyContent: "center",
			}}>
			<Box>
				<img
					src={`https://storage.googleapis.com/activscout/${image}`}
					style={{
						width: 310,
						height: 200,
						objectFit: "cover",
						borderRadius: 10,
					}}
				/>
				<Button
					onClick={handleClick}
					sx={{
						backgroundColor: "#1782FF",
						mb: 1.5,
						mt: 1,
						width: 180,
					}}>
					Launch Post
				</Button>
				<Typography
					level="title-lg"
					sx={{
						width: 250,
					}}>
					{title}
				</Typography>
			</Box>
		</Box>
	);
};

const ArrowButton = styled(Box)`
	background-color: #a40e4c;
	width: 40px;
	height: 40px;
	border-radius: 5px;
	display: flex !important;
	justify-content: center;
	align-items: center;
	:hover {
		background-color: rgba(164, 14, 76, 0.8);
	}
`;

const NewsCarousel: React.FC = ({}) => {
	const isMobile = useMemo(() => window.innerWidth < 768, []);
	const isTablet = useMemo(() => window.innerWidth < 1024, []);

	const slidesToScroll = isMobile ? 1 : 4;
	const sliderRef = useRef(null);
	const [activeIndex, setActiveIndex] = useState(0);
	const [selectedImage, setSelectedImage] = useState("");

	const { venueName } = useParams();
	const { data: getBlogPostsRes, isLoading } = useGetBlogPostsQuery(
		venueName || "",
		{
			skip: !venueName,
		}
	);

	const data = useMemo(
		() =>
			isMobile
				? getBlogPostsRes?.data
				: getBlogPostsRes?.data.reduce((acc: any[][], _, i: number) => {
						if (i % 6 === 0) {
							acc.push([getBlogPostsRes.data[i]]);
						} else {
							acc[acc.length - 1].push(getBlogPostsRes.data[i]);
						}
						return acc;
				  }, [] as any[][]),
		[getBlogPostsRes]
	);

	const items = useMemo(
		() =>
			isMobile
				? data?.map((news: any) => (
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-evenly",
								gap: 4,
							}}>
							<NewsPost title={news.title} image={news.image} id={news._id} />
						</Box>
				  ))
				: data?.map((news: any) => {
						const col1 = news.slice(0, 3);
						const col2 = news.slice(3, 6);
						return (
							<Box
								sx={{
									px: 2,
								}}>
								{
									// render as 2 columns with 3 rows
									<>
										<Box
											sx={{
												display: "grid",
												gridTemplateColumns: "1fr 1fr 1fr",
												gap: 4,
											}}>
											{col1.map((n: any) => (
												<NewsPost title={n.title} image={n.image} id={n._id} />
											))}
										</Box>
										<Box
											sx={{
												display: "grid",
												gridTemplateColumns: "1fr 1fr 1fr",
												gap: 4,
												mt: 4,
											}}>
											{col2.map((n: any) => (
												<NewsPost title={n.title} image={n.image} id={n._id} />
											))}
										</Box>
									</>
								}
							</Box>
						);
				  }),
		[data]
	);

	return (
		<Box
			sx={{
				px: {
					md: 5,
				},
				pb: 5,
				mt: 3,
			}}>
			<SectionHeader title="News and Press Releases" />

			{!data?.length && !isLoading ? (
				<Box sx={{ m: 15 }}>
					<Typography level="title-md" textAlign="center">
						No news posts available
					</Typography>
				</Box>
			) : (
				<Box sx={{ mt: 8, pl: 2, pr: 2 }}>
					<Slider
						onSwipe={(sd: string) => {
							if (sd === "left") {
								setActiveIndex(activeIndex + 1);
							} else {
								setActiveIndex(activeIndex - 1);
							}
						}}
						ref={sliderRef}
						slidesToShow={1}
						slidesToScroll={1}
						arrows={!isMobile && !isTablet && (items?.length as number) > 2}
						dots={false}
						infinite={false}
						speed={500}
						prevArrow={
							<ArrowButton>
								<ChevronLeft sx={{ color: "white" }} />
							</ArrowButton>
						}
						nextArrow={
							<ArrowButton>
								<ChevronRight sx={{ color: "white" }} />
							</ArrowButton>
						}>
						{items}
					</Slider>
				</Box>
			)}
			<ImageViewer src={selectedImage} />
		</Box>
	);
};

export default NewsCarousel;
