import { Box, Option, Select, Typography } from "@mui/joy";
import { ReactComponent as ArrowIcon } from "../Icons/arrow.svg";
import { FC, useEffect, useState } from "react";
import {
	useSaveCrowdCourtBookingMutation,
	useUpdateCrowdCourtBookingMutation,
} from "../apis/clubhub.api";

const bookingPlatforms = [
	"AceBook",
	"Active Network",
	"Amilia",
	"Appointment Plus",
	"Athliit",
	"Avacado (YMCA)",
	"Book it Tennis",
	"Book King",
	"Book My Court",
	"BookaBall",
	"Booklux",
	"BookTeq",
	"Class Software Solutions",
	"Club Automation",
	"Club Manager 365",
	"Club Manager Central",
	"Club Sentry",
	"Club Spark",
	"Club Wise",
	"ClubLocker",
	"Course Pro",
	"Court Reserve",
	"Daxko",
	"E-Booking Online",
	"EverSports",
	"EZ Facility",
	"EZ Runner",
	"Fast Courts",
	"Fitli",
	"Fitness Force",
	"Fusion",
	"GameTime",
	"GetOmnify",
	"Giga Sports",
	"Gladstone",
	"GotCourts",
	"Green 4 Solutions",
	"Hello Club",
	"i-Reservation",
	"In Zone",
	"JegySoft",
	"Jonas",
	"Jump Forward",
	"Kourts",
	"Legendware",
	"Matchi",
	"MiGym",
	"Mind and Body",
	"My Court",
	"My Courts",
	"North Star",
	"Open Play",
	"Paddeo",
	"Padeliq",
	"Perfect Mind",
	"Pike13",
	"Pivot",
	"Plan my Sport",
	"Planyo",
	"Playbypoint",
	"Playo",
	"Playtomic",
	"PlayVen",
	"PlayWaze",
	"PodPlay",
	"R2 Sports",
	"RecDesk",
	"RecTrac",
	"ResaSports",
	"Reserva Deportes",
	"Reserve My Court",
	"Scala Sports",
	"Simply Book",
	"Skedda",
	"SkyCourt",
	"Smash Tennis Bookings",
	"Sportlyzer",
	"SportyHQ",
	"Super SaaS",
	"Tennis Booker",
	"Tennis Bookings",
	"Tennis Plan",
	"Tennis Rungs",
	"TennisKhelo",
	"Total e Integrated",
	"Xplor",
];

interface CourtBookingCrowdSourceProps {
	clubCode: string;
}

const CourtBookingCrowdSource: FC<CourtBookingCrowdSourceProps> = ({
	clubCode,
}) => {
	const [selectedPlatform, setSelectedPlatform] = useState<string | null>(null);

	const [saveCrowdSource] = useSaveCrowdCourtBookingMutation();
	const [updateCrowdSource] = useUpdateCrowdCourtBookingMutation();

	useEffect(() => {
		const platform = localStorage.getItem("courtBookingPlatform_" + clubCode);
		if (platform) {
			setSelectedPlatform(JSON.parse(platform)?.bookingSystem);
		}
	}, [clubCode]);

	const handlePlatformSelect = async (e: any, value: any) => {
		try {
			setSelectedPlatform(value);
			const _local = localStorage.getItem("courtBookingPlatform_" + clubCode);

			const isUpdate = _local ? JSON.parse(_local)._id : false;

			let res;

			if (isUpdate) {
				res = await updateCrowdSource({
					clubCode,
					bookingSystem: value,
					_id: isUpdate,
				}).unwrap();
			} else {
				res = await saveCrowdSource({
					clubCode,
					bookingSystem: value,
				}).unwrap();
			}

			localStorage.setItem(
				"courtBookingPlatform_" + clubCode,
				JSON.stringify(res)
			);
		} catch (error) {
			alert("An error occurred while saving the platform");
			console.error(error);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: 3,
				pb: 5,
				pt: 1,
			}}>
			<Box sx={{ textAlign: "center" }}>
				<Typography level="body-sm">
					Crowd sourcing your management software.
				</Typography>
				<Typography level="body-sm">
					Select the software used by your club.
				</Typography>
			</Box>
			<Select
				value={selectedPlatform}
				onChange={handlePlatformSelect}
				sx={{ width: 350, bgcolor: "#D9D9D9", borderRadius: 0 }}
				placeholder="Select from our drop down list"
				indicator={<ArrowIcon width={16} height={16} />}>
				{bookingPlatforms.map((platform) => (
					<Option key={platform} value={platform}>
						{platform}
					</Option>
				))}
			</Select>
			{selectedPlatform && (
				<Typography
					level="body-sm"
					sx={{
						width: {
							xs: "100%",
							md: "50%",
						},
						textAlign: "center",
					}}>
					Thank you for helping us crowd-source your software. We are adding
					additional support for today’s top court booking systems and we look
					forward to including your court booking soon.
				</Typography>
			)}
		</Box>
	);
};

export default CourtBookingCrowdSource;
