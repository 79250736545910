import { FC, useEffect, useState } from "react";
import { ReactComponent as ChatSvg } from "./assets/chat.svg";
import FloatingChatbot from "./Chatbot";
import { useGetClubQuery } from "../apis/club.api";
import { Outlet, useParams } from "react-router-dom";

interface ChatBotProps {}

const defaultSettings = {
	enabled: true,
	primaryColor: "#1976d2",
	secondaryColor: "#115293",
	icon: null,
	welcomeMessage: "Hello, how can I help you?",
};

const ChatBot: FC<ChatBotProps> = () => {
	const [show, setShow] = useState(false);
	const [settings, setSettings] = useState(defaultSettings);

	const venueName = useParams().venueName as string;

	const { data: club } = useGetClubQuery(venueName, {
		skip: !venueName,
	});

	useEffect(() => {
		if (club && club.chatbotSettings) {
			setSettings(club.chatbotSettings);
		}
	}, [club]);

	const handleShow = () => {
		if (!localStorage.getItem("chatbot_name")) {
			const name = prompt("What's your name?");
			if (name) {
				localStorage.setItem("chatbot_name", name);
			} else {
				return;
			}
		}

		setShow(!show);
	};

	if (!settings.enabled)
		return (
			<>
				<Outlet />
			</>
		);

	return (
		<div
			style={{
				position: "relative",
			}}>
			{!show && (
				<button
					onClick={handleShow}
					style={{
						position: "fixed",
						bottom: 30,
						right: 30,
						cursor: "pointer",
						zIndex: 1000,
						padding: 10,
						borderRadius: "50%",
						backgroundColor: "white",
						boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
					}}>
					<ChatSvg width={40} />
				</button>
			)}
			<Outlet />
			<FloatingChatbot isOpen={show} setIsOpen={setShow} settings={settings} />
		</div>
	);
};

export default ChatBot;
