import { Box, Button, Drawer, IconButton, Typography } from "@mui/joy";
import logo from "../assets/logo.png";
import { Menu } from "@mui/icons-material";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../assets/Back 1.svg";

import LoginModal from "./LoginModal";
import useIsAdmin from "../hooks/useIsAdmin";
import { useGetClubQuery } from "../apis/club.api";

interface HeaderProps {
	newsRef: any;
	partnerNetworkRef: any;
	contactUsRef: any;
}

export default function Header({
	newsRef,
	partnerNetworkRef,
	contactUsRef,
}: HeaderProps) {
	const [open, setOpen] = useState(false);
	const menuItems = [
		"Home",
		"Court Finder",
		"News",
		"Partnered Network",
		"Contact Us",
	];
	const navigate = useNavigate();
	const scrollTo = (ref: any) => {
		ref.current.scrollIntoView({ behavior: "smooth" });
	};

	return (
		<Box
			sx={{
				px: {
					xs: 3,
					md: 10,
				},
				display: "flex",
				justifyContent: "space-between",
				py: 3,
			}}>
			<Box
				onClick={() => window.location.reload()}
				sx={{
					display: "flex",
					alignItems: "center",
					cursor: "pointer",
				}}>
				<img src={logo} alt="logo" width={45} height={45} />
				<Typography
					level="h4"
					sx={{ ml: 1, fontWeight: "700", color: "white" }}>
					CLUBHUB
				</Typography>
			</Box>
			<Box
				sx={{
					display: {
						xs: "none",
						md: "flex",
					},
					alignItems: "center",
				}}>
				{menuItems.map((item, index) => (
					<Button
						variant="plain"
						onClick={() => {
							if (item === "HOME") {
								// navigate("/");
								window.location.reload();
							} else if (item === "Court Finder") {
								navigate("/vu");
							} else if (item === "News") {
								scrollTo(newsRef);
							} else if (item === "Partnered Network") {
								scrollTo(partnerNetworkRef);
							} else if (item === "Contact Us") {
								scrollTo(contactUsRef);
							}
						}}
						sx={{
							":hover": {
								backgroundColor: "rgba(255, 255, 255, 0.1)",
							},
						}}
						key={index}>
						<Typography
							key={index}
							level="title-lg"
							sx={{
								mx: 2,
								color: "white",
								cursor: "pointer",
							}}>
							{item}
						</Typography>
					</Button>
				))}
			</Box>
			<Box
				sx={{
					display: {
						xs: "none",
						md: "flex",
					},
					alignItems: "center",
				}}>
				{/* <Button variant="solid">Order</Button> */}
			</Box>
			<IconButton
				onClick={() => setOpen(true)}
				variant="solid"
				sx={{
					display: {
						md: "none",
					},
				}}>
				<Menu />
			</IconButton>

			<Drawer open={open} onClose={() => setOpen(false)} anchor="right">
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						pt: 5,
					}}>
					{menuItems.map((item, index) => (
						<Button
							variant="plain"
							onClick={() => {
								if (item === "HOME") {
									// navigate("/");
									window.location.reload();
								} else if (item === "Court Finder") {
									navigate("/vu");
								} else if (item === "News") {
									scrollTo(newsRef);
								} else if (item === "Partnered Network") {
									scrollTo(partnerNetworkRef);
								} else if (item === "CONTACT US") {
									scrollTo(contactUsRef);
								}
							}}
							sx={{
								":hover": {
									backgroundColor: "rgba(255, 255, 255, 0.1)",
								},
							}}
							key={index}>
							<Typography
								key={index}
								level="title-lg"
								sx={{
									mx: 2,
									cursor: "pointer",
								}}>
								{item}
							</Typography>
						</Button>
					))}
					{/* <Button variant="solid">Order</Button> */}
				</Box>
			</Drawer>
		</Box>
	);
}

interface NavHeaderProps {
	transparent?: boolean;
	onAdminClick?: () => void;
	style?: React.CSSProperties;
	color?: string;
	showLogout?: boolean;
	disabledColor?: string;
}

export const NavHeader: FC<NavHeaderProps> = ({
	transparent,
	onAdminClick,
	style,
	color,
	showLogout = true,
	disabledColor,
}) => {
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();
	const { venueName } = useParams();

	const isAdmin = useIsAdmin(venueName || "");

	const { data: club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const headerStyle = useMemo(
		() =>
			transparent
				? { ...styles.header, backgroundColor: "transparent" }
				: styles.header,
		[transparent]
	);

	const _onAdminPress = () => {
		// open in new tab
		window.open("https://clubhub-admin.vercel.app/?venueName=" + venueName);
	};

	const home = "/vu/" + venueName;

	const plan = club?.plan?.planType || "basic";

	const tabs = useMemo(
		() => [
			{
				title: "Home",
				route: home,
			},
			{
				title: "Gallery",
				route: home + "/gallery",
				disabled: plan === "basic",
			},
			{
				title: "Court Booking",
				route: home + "/courtbooking",
			},
			{
				title: "Blog",
				route: home + "/#news",
				disabled: plan !== "premium",
			},
			{
				title: "Admin",
				route: home + "/admin",
			},
		],
		[home, plan]
	);

	return (
		<div>
			<div
				style={{
					...headerStyle,
					...style,
				}}>
				<Box sx={styles.headerText} onClick={() => navigate("/vu/")}>
					<BackIcon width={20} height={20} fill={color || "white"} />
					<Typography sx={{ color: color || "white" }}> Back to map</Typography>
				</Box>
				<Box
					sx={{
						display: {
							xs: "none",
							lg: "flex",
						},
						alignItems: "center",
						gap: 10,
						position: "absolute",
						left: "50%",
						transform: "translateX(-50%)",
					}}>
					{tabs.map((tab) => (
						<Typography
							sx={{
								color: tab.disabled
									? disabledColor || "rgba(255, 255, 255, 0.5)"
									: color || "white",
								cursor: tab.disabled ? "not-allowed" : "pointer",
							}}
							onClick={() => {
								if (tab.disabled) return;
								if (tab.title === "Admin") {
									_onAdminPress();
								} else {
									navigate(tab.route);
								}
							}}>
							{tab.title}
						</Typography>
					))}
				</Box>
				{showLogout && (
					<Button
						variant="plain"
						onClick={() => {
							localStorage.removeItem("clubhub_token");
							localStorage.removeItem("clublocker_token");
							localStorage.removeItem("clublocker_user_id");
							localStorage.removeItem("user");

							window.location.href = "/vu/" + venueName;
						}}
						sx={{
							display: {
								xs: "none",
								lg: "flex",
							},
							borderColor: color || "white",
							color: color || "white",
							":hover": {
								backgroundColor: "rgba(255, 255, 255, 0.1)",
							},
						}}>
						Logout
					</Button>
				)}
				<IconButton
					onClick={() => setDrawerOpen(true)}
					variant="outlined"
					sx={{
						display: {
							xs: "flex",
							lg: "none",
						},
						borderColor: color || "white",
					}}>
					<Menu sx={{ color: color || "white" }} />
				</IconButton>
			</div>
			<Drawer
				anchor="right"
				open={drawerOpen}
				onClose={() => setDrawerOpen(false)}>
				<Box
					sx={{
						width: "100%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						pt: 5,
						gap: 2,
					}}>
					{tabs.map((tab) => (
						<Typography
							sx={{ color: "black", cursor: "pointer" }}
							onClick={() => {
								if (tab.title === "Admin") {
									_onAdminPress();
								} else {
									navigate(tab.route);
								}
							}}>
							{tab.title}
						</Typography>
					))}
				</Box>
			</Drawer>
		</div>
	);
};

const styles: {
	[key: string]: React.CSSProperties;
} = {
	header: {
		// width: "100%",
		height: 50,
		backgroundColor: "#1F3A6E",
		display: "flex",
		padding: 10,
		justifyContent: "space-between",
		alignItems: "center",
	},
	headerText: {
		color: "white",
		display: "flex",
		alignItems: "center",
		gap: 2,
		cursor: "pointer",
	},
	headerLogo: {
		width: 25,
	},
	headerTitle: {
		width: 40,
		height: 40,
	},
};
