import { Box, Button, Typography, styled } from "@mui/joy";
import { ReactComponent as ClubIcon } from "../assets/Club.svg";
import { ReactComponent as BookingSystemIcon } from "../assets/booking-system.svg";
import { ReactComponent as EventManagementIcon } from "../assets/event-management.svg";
import { ReactComponent as GlobeIcon } from "../assets/Globe.svg";
import SectionHeader from "./SectionHeader";

const styles = {
	container: {
		height: 280,
		mt: 7,
	},
	headerIcon: {
		width: 35,
	},
	mainBox: {
		mt: 6,
		display: "flex",
		gap: 1,
		flexDirection: {
			xs: "column",
			sm: "row",
		},
		overflowY: "auto",
		justifyContent: "space-evenly",
		alignItems: {
			xs: "center",
			sm: "flex-start",
		},
	},
	columnBox: {
		display: "flex",
		flexDirection: "column",
		gap: 1,
		width: 200,
	},
	itemBox: {
		width: 300,
		background: "#D0d0d0",
		height: 40,
		display: "flex",
		alignItems: "center",
		p: 1,
	},
	courtsBox: {
		display: "flex",
		pl: 4,
	},
	courtItemBox: {
		width: 200,
		height: 50,
		padding: 1,
	},
	titleFont: {
		fontWeight: "500",
		color: "#1F3A6E",
	},
	valueFont: {
		color: "#7f7f7f",
		textAlign: "center",
		fontSize: 14,
		fontWeight: "500",
	},
};

const Img = styled("img")(({ theme }) => ({
	width: 400,
	[theme.breakpoints.down("sm")]: {
		width: 200,
	},
}));

const facilities = [
	{
		title: "Individual Clubs",
		text: "We help to optimize membership and create a better member experience",
		icon: ClubIcon,
	},
	{
		title: "Booking Systems",
		text: "We make it easy to work with the highest performing club management systems via our API",
		icon: BookingSystemIcon,
	},
	{
		title: "Event management",
		text: "Interconnecting with event management systems to help interface with their audience",
		icon: EventManagementIcon,
	},
	{
		title: "Associations",
		text: "Providing web infrastructure to Federations and Associations around the globe",
		icon: GlobeIcon,
	},
];

export default function StakeHolders() {
	return (
		<div style={{ position: "relative" }}>
			<Box
				sx={{
					px: {
						xs: 1,
						md: 10,
					},
					py: 10,
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
					alignItems: "center",
					textAlign: "center",
					bgcolor: "#d0d0d0",
				}}>
				<SectionHeader title="STAKEHOLDERS" />
				<Typography level="body-md" mt={2} sx={{ color: "#7f7f7f" }}>
					Partnering with leading organizations worldwide to increase everyone’s
					productivity
				</Typography>
				<Box sx={styles.mainBox}>
					{facilities.map((facility, index) => (
						<Box
							key={index}
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								width: {
									md: 200,
									lg: 250,
								},
								gap: 1,
							}}>
							<Box
								sx={{
									display: {
										xs: "block",
										sm: "none",
									},
									width: 250,
								}}
							/>
							<facility.icon width={50} stroke="#E89201" />
							<Typography sx={styles.titleFont}>
								{facility.title.toUpperCase()}
							</Typography>
							<Typography sx={styles.valueFont}>{facility.text}</Typography>
						</Box>
					))}
				</Box>
			</Box>
		</div>
	);
}
