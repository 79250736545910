import React, { FC, useEffect, useState } from "react";
import {
	Box,
	Button,
	Typography,
	IconButton,
	Sheet,
	Stack,
	Input,
} from "@mui/joy";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useChatStore } from "./store/chatStore";
import { useParams } from "react-router-dom";

interface FloatingChatbotProps {
	isOpen: boolean;
	setIsOpen: (isOpen: boolean) => void;
	settings: {
		enabled: boolean;
		primaryColor: string;
		secondaryColor: string;
		icon: string | null;
		welcomeMessage: string;
	};
}

const FloatingChatbot: FC<FloatingChatbotProps> = ({
	isOpen,
	setIsOpen,
	settings,
}) => {
	const { messages, addMessage, greet } = useChatStore();
	const [message, setMessage] = useState("");

	const venueName = useParams().venueName as string;

	useEffect(() => {
		if (settings.enabled && isOpen) {
			if (messages.length === 0) {
				setTimeout(() => greet(settings.welcomeMessage), 1000);
			}
		}
	}, [settings?.enabled, isOpen]);

	const handleSend = (e?: React.FormEvent<HTMLFormElement>) => {
		e?.preventDefault();
		addMessage(
			{
				text: message,
				user: "me",
			},
			venueName
		);
		setMessage("");
		e?.currentTarget.reset();
	};

	return (
		<Box
			sx={{
				position: "fixed",
				bottom: {
					xs: isOpen ? 0 : 16,
					sm: 16,
				},
				right: {
					xs: isOpen ? 0 : 16,
					sm: 16,
				},
				zIndex: 99,
			}}>
			{isOpen && (
				<Sheet
					variant="outlined"
					sx={{
						width: {
							xs: "100vw",
							sm: 400,
						},
						height: {
							xs: "100vh",
							sm: 600,
						},
						borderRadius: "md",
						boxShadow: "lg",
						display: "flex",
						flexDirection: "column",
						zIndex: 1000,
					}}>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							px: 2,
							py: 1.5,
							backgroundColor: settings.primaryColor,
							borderRadius: "md",
							color: "white",
							borderBottomLeftRadius: 0,
							borderBottomRightRadius: 0,
						}}>
						<Box
							sx={{
								display: "flex",
								gap: 1,
							}}>
							{settings.icon && (
								<img
									src={
										"https://storage.googleapis.com/activscout/" + settings.icon
									}
									width={30}
									height={25}
									alt="Chatbot Icon"
								/>
							)}
							<Typography level="h4" sx={{ color: "white" }}>
								Chatbot
							</Typography>
						</Box>
						<IconButton
							size="sm"
							onClick={() => setIsOpen(false)}
							sx={{ color: "white" }}>
							<CloseRoundedIcon sx={{ color: "white" }} />
						</IconButton>
					</Box>

					<Box
						sx={{
							flex: 1,
							p: 2,
							overflowY: "auto",
							display: "flex",
							flexDirection: "column",
							gap: 1.5,
						}}>
						{messages.length === 0 && (
							<Typography
								level="body-sm"
								textAlign="center"
								sx={{ color: "#9e9e9e" }}>
								Start the conversation!
							</Typography>
						)}

						{messages.map((msg, index) =>
							msg.loading ? (
								<Box
									sx={{
										alignSelf: "flex-start",
										backgroundColor: "#f5f5f5",
										color: "#616161",
										px: 2,
										py: 1,
										borderRadius: "20px",
										borderTopLeftRadius: "0px",
										borderTopRightRadius: "20px",
										maxWidth: "70%",
										wordBreak: "break-word",
										boxShadow: "sm",
									}}>
									<div className="typing-indicator">
										<span></span>
										<span></span>
										<span></span>
									</div>
								</Box>
							) : (
								<Box
									key={index}
									sx={{
										alignSelf: msg.user === "bot" ? "flex-start" : "flex-end",
										backgroundColor: msg.user === "bot" ? "#f5f5f5" : "#d1e9ff",
										color: msg.user === "bot" ? "#616161" : "#0d47a1",
										px: 2,
										py: 1,
										borderRadius: "20px",
										borderTopLeftRadius: msg.user === "bot" ? "0px" : "20px",
										borderTopRightRadius: msg.user === "bot" ? "20px" : "0px",
										maxWidth: "70%",
										wordBreak: "break-word",
										boxShadow: "sm",
									}}>
									{msg.text}
								</Box>
							)
						)}
					</Box>

					<Box
						sx={{
							p: 2,
							borderTop: "1px solid",
							borderColor: "#e0e0e0",
						}}>
						<Stack direction="row" spacing={1}>
							<Input
								placeholder="Type your message..."
								value={message}
								onChange={(e: any) => setMessage(e.target.value)}
								onKeyDown={(e: any) => e.key === "Enter" && handleSend(e)}
								sx={{ flex: 1 }}
							/>
							<Button
								onClick={() => handleSend()}
								variant="solid"
								sx={{
									backgroundColor: settings.secondaryColor,
									color: "white",
									"&:hover": {
										backgroundColor: `color-mix(in srgb, ${settings.secondaryColor} 90%, transparent)`,
									},
								}}>
								Send
							</Button>
						</Stack>
					</Box>
				</Sheet>
			)}
		</Box>
	);
};

export default FloatingChatbot;
