import { Box, Button, Card, Tab, TabList, Tabs, Typography } from "@mui/joy";
import AdminHeader from "../../Components/AdminHeader";
import PricingCard from "../../Components/PricingCard";
import SectionHeader from "../../Components/SectionHeader";
import PricingPlanComparison from "../../Components/PricingPlanComparison";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useGetClubQuery } from "../../apis/club.api";
import SubscriptionForm from "../../Components/SubscriptionForm";

const PricingScreen = () => {
	const [tabIndex, setTabIndex] = useState(0);
	const [selectedPlan, setSelectedPlan] = useState<null | string>(null);
	const { venueName } = useParams<{ venueName: string }>();

	const { data: club } = useGetClubQuery(venueName as string);

	const plan = club?.plan;

	return (
		<div>
			<AdminHeader />

			<Box p={4}>
				<Tabs
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index as number)}>
					<TabList>
						<Tab>Subscription</Tab>
						<Tab>Pricing</Tab>
					</TabList>
				</Tabs>
				{tabIndex === 0 && (
					<Box mt={5}>
						<Card sx={{ width: 500 }}>
							<Typography level="h3">Current Plan</Typography>
							<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
								<Box
									sx={{
										backgroundColor: "#F0F0F0",
										px: 2,
										py: 0.5,
										width: "fit-content",
									}}>
									<Typography level="h4">
										{plan?.planType?.toUpperCase()}
									</Typography>
								</Box>
								<Typography level="h4">Plan</Typography>
							</Box>
							<Box>
								<Typography level="title-lg">Price: $25</Typography>
								<Typography level="title-lg">
									Ends on:{" "}
									{new Date(plan?.subscriptionEnd).toLocaleDateString()}
								</Typography>
							</Box>
							<Button onClick={() => setTabIndex(1)} sx={{ mt: 5 }}>
								Change Plan
							</Button>
						</Card>
					</Box>
				)}
				{tabIndex === 1 && (
					<Box mt={5}>
						<SectionHeader title="Pricing" showBorder borderColor="#7F7F7F" />
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
								alignItems: {
									xs: "center",
									sm: "flex-end",
								},
								gap: 5,
								mt: 5,
								flexDirection: {
									xs: "column",
									sm: "row",
								},
							}}>
							<PricingCard
								type="listing"
								onSignUpPress={(type) => setSelectedPlan(type)}
							/>
							<PricingCard
								type="basic"
								onSignUpPress={(type) => setSelectedPlan(type)}
							/>
							<PricingCard
								type="premium"
								onSignUpPress={(type) => setSelectedPlan(type)}
							/>
						</Box>

						<PricingPlanComparison />
					</Box>
				)}

				<SubscriptionForm
					planName={selectedPlan}
					open={!!selectedPlan}
					onClose={() => setSelectedPlan(null)}
				/>
			</Box>
		</div>
	);
};

export default PricingScreen;
