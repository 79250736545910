import axios from "axios";
import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface ChatStore {
	messages: {
		text?: string;
		user?: string;
		loading?: boolean;
	}[];
	addMessage: (message: any, clubId: string) => void;
	clearMessages: () => void;
	greet: (msg: string) => void;
	getMessages: () => any[];
}

const API_URL = "https://chatbot.ctoninja.tech/api/chat";
// const API_URL = "http://localhost:5001/api/chat";

export const useChatStore = create<ChatStore>()(
	devtools((set, get) => ({
		messages: [],
		addMessage: async (message, clubId) => {
			set((state: any) => ({
				messages: [...state.messages, message, { loading: true }],
			}));

			const res = await axios.post(API_URL, {
				message: message.text,
				username: localStorage.getItem("chatbot_name"),
				chatId: localStorage.getItem("chatbot_chatId"),
				clubId,
			});

			if (res.data.text) {
				const msg = {
					text: res.data.text.result,
					user: "bot",
				};
				const messages = get().messages;
				messages.pop();

				set((state: any) => ({ messages: [...messages, msg] }));
				if (res.data.chatId) {
					localStorage.setItem("chatbot_chatId", res.data.chatId);
				}
			} else {
				const msg = {
					text: "Something went wrong",
					user: "bot",
				};
				set((state: any) => ({ messages: [...state.messages, msg] }));
			}
		},
		greet: (msg) => {
			set((state: any) => ({
				messages: [
					...state.messages,
					{
						text: msg || "Hello, I am a bot. How can I help you today?",
						user: "bot",
					},
				],
			}));
		},
		clearMessages: () => set({ messages: [] }),
		getMessages: () => get().messages,
	}))
);
