import "./App.css";
import { CssVarsProvider, extendTheme } from "@mui/joy";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
	useLocation,
} from "react-router-dom";
import Home from "./routes/Home";
import Clubsites from "./routes/Clubsites";
import CourtBooking from "./routes/CourtBooking";
import FindCourts from "./routes/FindCourts";
// import Maps from "./routes/Maps";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VenuesMap from "./routes/VenuesMap";
import LoginScreen from "./admin/screens/LoginScreen";
import AuthLayout from "./admin/components/AuthLayout";
import SidebarComponent from "./admin/components/SidebarComponent";
import InitializingScreen from "./admin/screens/InitializingScreen";
import ImagesScreen from "./admin/screens/ImagesScreeen";
import DashboardScreen from "./admin/screens/DashboardScreen";
import TextsScreen from "./admin/screens/TextsScreen";
import TournamentScreen from "./admin/screens/TournamentsScreen";
import Admin from "./routes/Admin";
import PluginCoverScreen from "./routes/PluginCover";
import ClubAdmin from "./routes/Admin/ProgramingScreen";
import Blog from "./routes/Admin/Blog";
import AdminHome from "./routes/Admin/AdminHome";
import { Provider } from "react-redux";
import store from "./store";
import EditFees from "./routes/Admin/FeesAndMemberships";
import useAuth from "./hooks/useAuth";
import CourtBookingAdmin from "./routes/Admin/CourtBookingAdmin";
import BlogPost from "./routes/BlogPost";
import Gallery from "./routes/Gallery";
import AdminGallery from "./routes/Admin/AdminGallery";
import PricingScreen from "./routes/Admin/PricingScreen";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import ChatBot from "./chatbot";

const stripePromise = loadStripe(
	"pk_test_51QLuACEKuYo6kayAeq8Ua27B7NLDixZ00zwm0KhRBD4P4eGlH5PQJY69GeOCQEYm3rLK3ZDX6DugAR8wg6Er4U9U00IY3TqN32"
);

function App() {
	const theme = extendTheme({
		fontFamily: {
			body: "Nunito, sans-serif",
			display: "Nunito, sans-serif",
		},
	});

	const auth = useAuth();

	const venueName = window.location.pathname.split("/")[2];

	return (
		<CssVarsProvider theme={theme}>
			<Provider store={store}>
				<Elements stripe={stripePromise}>
					<Router>
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/vu" element={<VenuesMap />} />
							<Route element={<ChatBot />}>
								<Route path="/vu/:venueName" element={<Clubsites />} />
								<Route path="/find-courts" element={<FindCourts />} />
								{/* <Route path="/maps" element={<Maps />} /> */}

								<Route
									path="/vu/:venueName/blog/:blogId"
									element={<BlogPost />}
								/>
								<Route
									path="/vu/:venueName/courtbooking"
									element={<CourtBooking />}
								/>
								<Route path="/vu/:venueName/gallery" element={<Gallery />} />
							</Route>

							<Route path="/plugincover" element={<PluginCoverScreen />} />
							{auth.isLoggedIn ? (
								<>
									<Route path="/vu/:venueName/admin" element={<AdminHome />} />

									<Route
										path="/vu/:venueName/admin/programing"
										element={<ClubAdmin />}
									/>
									<Route path="/vu/:venueName/admin/blog" element={<Blog />} />
									<Route
										path="/vu/:venueName/admin/fees"
										element={<EditFees />}
									/>
									<Route
										path="/vu/:venueName/admin/courtbooking"
										element={<CourtBookingAdmin />}
									/>
									<Route
										path="/vu/:venueName/admin/gallery"
										element={<AdminGallery />}
									/>
									<Route
										path="/vu/:venueName/admin/pricing"
										element={<PricingScreen />}
									/>
								</>
							) : (
								<>
									<Route
										path="*"
										element={<Navigate to={`/vu/${venueName}/#login`} />}
									/>
								</>
							)}
						</Routes>
					</Router>
				</Elements>
			</Provider>
		</CssVarsProvider>
	);
}

export default App;
