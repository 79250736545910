import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
	baseUrl: "https://api.clubhub.net",
});

export const squashFinderApi = createApi({
	reducerPath: "squashFinderApi",
	baseQuery,
	endpoints: (builder) => ({
		getVenues: builder.query<any[], void>({
			query: () => "/venues/all",
		}),
		getVenue: builder.query<any, string>({
			query: (venueName) => `/venues/get-by-name/${venueName}`,
		}),
	}),
});

export const { useGetVenuesQuery, useGetVenueQuery } = squashFinderApi;
