import React, { FC, useMemo } from "react";
import { Box, Typography } from "@mui/joy";
import SectionHeader from "./SectionHeader";
import { ReactComponent as ClubIcon } from "../assets/Club.svg";
import { ReactComponent as MembersIcons } from "../assets/Members Logo.svg";
import { ReactComponent as GuestsIcon } from "../assets/Members Logo-2.svg";
import { ReactComponent as PayIcon } from "../assets/Pay Icon.svg";
import { ReactComponent as SquashCourtIcon } from "../assets/Squash Court.svg";

interface SquashFacilitiesProps {
	membersOnly: string;
	guestsWelcome: string;
	payAndPlay: string;
	totalCourts: number;
	singleCourts: number;
	doubleCourts: number;
	iconColor?: string;
	venueCategory: number;
	hardBallCourts: number;
}

const styles = {
	container: {
		height: {
			xs: "auto",
			sm: "50%",
		},
		mt: 7,
	},
	headerIcon: {
		width: 35,
	},
	mainBox: {
		mt: 8,
		display: "flex",
		gap: 1,
		flexDirection: {
			xs: "column",
			md: "row",
		},
		justifyContent: "space-evenly",
		alignItems: {
			xs: "center",
			md: "flex-start",
		},
	},
	columnBox: {
		display: "flex",
		flexDirection: "column",
		gap: 1,
		width: 200,
	},
	itemBox: {
		// width: 300,
		background: "#D0d0d0",
		height: 40,
		display: "flex",
		alignItems: "center",
		p: 1,
	},
	courtsBox: {
		display: "flex",
		pl: 4,
	},
	courtItemBox: {
		// width: 200,
		height: 50,
		padding: 1,
	},
	titleFont: {
		fontWeight: "500",
		color: "#1F3A6E",
	},
	valueFont: {
		color: "#7f7f7f",
		textAlign: "center",
		fontSize: 14,
		whiteSpace: "pre-wrap",
	},
};

const facilities = ({
	membersOnly,
	guestsWelcome,
	payAndPlay,
	totalCourts,
	singleCourts,
	doubleCourts,
	venueCategory,
	hardBallCourts,
}: SquashFacilitiesProps) => [
	{
		title: "Venue Category",
		text: venueCategories[venueCategory - 1],
		icon: ClubIcon,
	},
	{
		title: "Members",
		text:
			membersOnly === "Yes"
				? "This is a members-only facility. Contact the club for more information."
				: membersOnly === "No"
				? "This is NOT a member-only facility and maintains broader accessibility."
				: "It is unknown if this is a ‘Members Only facility.’ See Squash Players App.",
		icon: MembersIcons,
	},
	{
		title: "Guests",
		text:
			guestsWelcome === "Yes"
				? "Yes, guests are welcome. Please consult facility staff as conditions apply."
				: guestsWelcome === "No"
				? "No. This is an exclusive facility. Guests are prohibited without special consideration."
				: "It is uncertain if guests are welcome. See Squash Players App.",
		icon: GuestsIcon,
	},
	{
		title: "Pay & Play",
		text:
			payAndPlay === "Yes"
				? "You are welcome to drop into this facility. Please consult current rates."
				: payAndPlay === "No"
				? "No, this is not a pay-to-play facility."
				: "It is uncertain if this is a pay-to-play facility. See Squash Players App.",
		icon: PayIcon,
	},
	{
		title: "Courts",
		text:
			totalCourts === 0
				? "There are 0 courts in total."
				: `${totalCourts} ${
						totalCourts === 1 ? "court" : "courts"
				  } in total. \n${singleCourts ? singleCourts : 0} single court${
						singleCourts > 1 ? "s" : ""
				  } \n${
						doubleCourts + hardBallCourts
							? doubleCourts +
							  hardBallCourts +
							  " double court" +
							  (doubleCourts + hardBallCourts > 1 ? "s" : "")
							: ""
				  } 
		`,
		icon: SquashCourtIcon,
	},
];

const venueCategories = [
	"Other - Miscellaneous squash facilities categorized as “Other”. See Squash Players App.",
	"Leisure Centre - Leisure Centre. A public recreational facility, for fitness and sports.",
	"School - Squash Courts on School grounds for student training and recreational play.",
	"Gym or Health & Fitness Centre - A gym and fitness primary facility with squash courts.",
	"Dedicated Facility - Dedicated facility, exclusively designed for squash with amenities.",
	"Don’t Know - More information about this venue is required. See Squash Players App.",
	"Hotel or Resort - Hotel or Resort squash courts for guest recreation, fitness, and leisure play",
	"College or University - College and University squash facilities for recreation, training, and competitions.",
	"Military - Military squash courts for physical training and recreation on base",
	"Shopping Centre - Shopping centre squash courts. Sport and fitness amidst retail convenience",
	"Community Hall - Community Hall squash courts for recreation, programs, and local events",
	"Private Residence - Courts in a private residence, exclusively for personal and social recreation",
	"Business Complex - Business Complex squash courts for fitness, recreation, and corporate wellness",
	"Private Club - A private club with exclusive facilities and services.",
	"Country Club - A Country Club with premium sports, leisure, dining, and social facilities.",
];

const SquashFacilities: FC<SquashFacilitiesProps> = ({
	membersOnly,
	guestsWelcome,
	payAndPlay,
	totalCourts,
	singleCourts,
	doubleCourts,
	iconColor = "#E89201",
	venueCategory,
	hardBallCourts,
}) => {
	const valueBoxStyle = useMemo(
		() => ({ ...styles.itemBox, background: "transparent", paddingLeft: 5 }),
		[]
	);

	const _facilities = useMemo(
		() =>
			facilities({
				membersOnly,
				guestsWelcome,
				payAndPlay,
				totalCourts,
				singleCourts,
				doubleCourts,
				venueCategory,
				hardBallCourts,
			}),
		[
			membersOnly,
			guestsWelcome,
			payAndPlay,
			totalCourts,
			singleCourts,
			doubleCourts,
			venueCategory,
			hardBallCourts,
		]
	);
	return (
		<Box sx={styles.container}>
			<SectionHeader
				iconStyle={styles.headerIcon}
				title={"SQUASH FACILITIES"}
				borderColor="#fff"
				icon={null}
			/>
			<Box>
				<Typography
					fontSize={12}
					width={{
						xs: "100%",
						sm: "50%",
					}}
					margin="auto"
					textAlign={"center"}
					sx={{ color: "#7f7f7f" }}>
					The following information has been crowdsourced from the global squash
					community. If you notice any inaccuracies, please download the Squash
					Players app to submit a correction request.
				</Typography>
			</Box>

			<Box sx={styles.mainBox}>
				{_facilities.map((facility, index) => (
					<Box
						key={index}
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
							width: 400,
							gap: 1,
						}}>
						<Box
							sx={{
								width: 200,
								display: {
									xs: "block",
									sm: "none",
								},
							}}
						/>
						<facility.icon stroke={iconColor} />
						<Typography sx={styles.titleFont}>
							{facility.title.toUpperCase()}
						</Typography>
						<Typography sx={styles.valueFont}>{facility.text}</Typography>
					</Box>
				))}
			</Box>
		</Box>
	);
};

export default SquashFacilities;
