import { Box, Typography } from "@mui/joy";
import { NavHeader } from "./Header";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetClubQuery } from "../apis/clubhub.api";
import { useGetVenueQuery } from "../apis/squashFinder.api";

const ClubHeader = () => {
	const venueName = useParams<{ venueName: string }>().venueName;

	const { data: club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const { data: venue } = useGetVenueQuery(venueName || "");

	return (
		<Box
			sx={{
				minHeight: 200,
				borderBottom: "10px solid #1f3a6e",
				backgroundImage: `url(${require("../assets/hero1.png")})`,
				backgroundSize: "fill",
				pb: 5,
			}}>
			<NavHeader transparent showLogout={false} />
			<Box
				sx={{
					width: {
						xs: "90%",
						md: "50%",
					},
					mx: {
						xs: "auto",
						md: 10,
					},
				}}>
				{club?.logo && (
					<img
						src={"https://storage.googleapis.com/activscout/" + club.logo}
						alt="logo"
						width={60}
						height={60}
					/>
				)}
				<Typography
					sx={{
						color: "black",
						fontWeight: "600",
						fontSize: {
							xs: 36,
							sm: 40,
						},
						mb: {
							xs: 1,
							sm: 2,
						},
					}}>
					{venue?.name?.toUpperCase()}
				</Typography>
				<Typography
					fontSize={18}
					sx={{
						color: "#7f7f7f",
					}}>
					{venue?.physical_address}
				</Typography>
			</Box>
		</Box>
	);
};

export default ClubHeader;
