import { Box, Button, Typography, styled } from "@mui/joy";
import { NavHeader } from "../Components/Header";
import Partners from "../Components/Partners";
import Footer from "../Components/Footer";
import herobackground from "../assets/clubbanner.gif";
import herobackgroundBlue from "../assets/Blue-Banner 1.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import FeesAndMemberships from "../Components/FeesAndMemberships";
import SquashFacilities from "../Components/SquashFacilities";
import VenueFacilities from "../Components/VenueFacilities";
import Programming from "../Components/Programming";
import LoginModal from "../Components/LoginModal";
import NewsCarousel from "../Components/NewsCarousel";
import { useGetClubImagesQuery, useGetClubQuery } from "../apis/clubhub.api";
import { useGetVenueQuery } from "../apis/squashFinder.api";
import useTournaments from "../hooks/useTournaments";
import ImageCarousel from "../Components/ImageCarousel";
import SectionHeader from "../Components/SectionHeader";
import ContactUs from "../Components/ContactUs";
import { ReactComponent as SquashPlayersIcon } from "../assets/squash-players.svg";
import { isMobile } from "react-device-detect";

const Img = styled("img")();

export default function Clubsites() {
	const location = useLocation();
	const [loginModalVisible, setLoginModalVisible] = useState(false);
	const [scrollY, setScrollY] = useState(0);

	const homeRef = useRef<HTMLDivElement>(null);
	const aboutRef = useRef<HTMLDivElement>(null);
	const contactUsRef = useRef<HTMLDivElement>(null);
	const newsRef = useRef<HTMLDivElement>(null);

	const venueName = useParams<{ venueName: string }>().venueName;

	const { data: _club } = useGetClubQuery(venueName || "", {
		skip: !venueName,
	});

	const { tournaments, isLoading: isTournamentsLoading } = useTournaments();

	const { data: venue } = useGetVenueQuery(venueName || "");

	const { data } = useGetClubImagesQuery(
		{ venueName: venueName || "" },
		{
			skip: !venueName,
		}
	);

	useEffect(() => {
		if (location.hash === "#login") {
			setLoginModalVisible(true);
		} else if (location.hash === "#news") {
			newsRef.current?.scrollIntoView({ behavior: "smooth" });
		}
	}, [location.hash]);

	useEffect(() => {
		const handleScroll = () => {
			setScrollY(window.scrollY); // Track how far the page has been scrolled
		};

		window.addEventListener("scroll", handleScroll);
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	// Calculate height dynamically based on scroll position, with a min and max height.
	const dynamicHeight = Math.min(100 * (scrollY / window.innerHeight), 100); // 200vh max

	const plan: "basic" | "standard" | "premium" = useMemo(() => {
		const _plan = window.location.search.split("plan=")[1];
		if (_plan) return _plan;
		else return _club?.plan?.planType || "basic";
	}, [_club]);

	return (
		<div>
			<Box
				id="header-parent"
				sx={{
					position: "relative",
					// height: `1vh`,
					height: {
						xs: "120vh",
						sm: "115vh",
						md: "105vh",
						lg: "135vh",
					},
					transition: "height 0.2s",
				}}>
				<Box
					sx={{
						width: "100%",
						height: "100vh", // Full viewport height
						position: "absolute", // Stay fixed during scrolling
						bottom: 0,
						left: 0,
						zIndex: -1, // Behind all other content
					}}>
					<img
						src={
							!venue?.no_of_glass_courts
								? require("../assets/non_glass_bg.jpg")
								: require("../assets/bg1.png")
						}
						id="bg"
						style={{
							width: "100%",
							height: "100vh", // Full viewport height
							objectFit: "cover",
						}}
					/>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							textAlign: "center",
						}}>
						<Typography
							sx={{
								color: "white",
								fontSize: 150,
								fontWeight: "700",
								height: 170,
							}}>
							{venue?.no_of_courts || 0}
						</Typography>
						<Typography
							sx={{ color: "white", fontWeight: "300", fontSize: 34 }}>
							Courts
						</Typography>
					</Box>
				</Box>
				<Box
					ref={homeRef}
					sx={{
						width: "100%",
						height: "700px",
						position: "absolute",
						top: `-${dynamicHeight}vh`,
					}}>
					<img
						src={plan === "premium" ? herobackgroundBlue : herobackground}
						alt="hero"
						style={{
							width: "100%",
							height: "100%",
							position: "absolute",
							zIndex: -1,
							objectFit: "cover",
						}}
					/>
					<NavHeader
						transparent
						onAdminClick={() => setLoginModalVisible(true)}
						style={{
							position: "fixed",
							top: 0,
							backgroundColor: `rgba(255, 255, 255, ${dynamicHeight / 20})`,
							width: "100%",
							transition: "background-color 0.1s",
							zIndex: 1,
							boxShadow:
								dynamicHeight > 10 ? "0 0 10px rgba(0, 0, 0, 0.1)" : "none",
						}}
						// on scroll, make the header text color change from white to black
						color={dynamicHeight > 10 ? "black" : "white"}
						showLogout={false}
						disabledColor={
							dynamicHeight > 10
								? "rgba(0, 0, 0, 0.5)"
								: "rgba(255, 255, 255, 0.5)"
						}
					/>

					<Box
						sx={{
							width: {
								xs: "90%",
								md: "50%",
							},
							mx: {
								xs: "auto",
								md: 10,
							},
							mt: 9,
						}}>
						{plan !== "basic" && _club?.logo ? (
							<Img
								src={"https://storage.googleapis.com/activscout/" + _club.logo}
								alt="logo"
								width={60}
								height={60}
							/>
						) : (
							<Box height={50} />
						)}
						<Typography
							sx={{
								color: "black",
								fontWeight: "600",
								fontSize: {
									xs: 36,
									sm: 40,
								},
							}}>
							{venue?.name?.toUpperCase()}
						</Typography>
						{_club?.altName && plan !== "basic" && (
							<Typography
								fontSize={18}
								sx={{
									color: "#000",
									fontWeight: "500",
									mb: 0.5,
								}}>
								"{_club?.altName}"
							</Typography>
						)}
						<Typography
							fontSize={18}
							sx={{
								color: plan === "premium" ? "#1F3A6E" : "#7f7f7f",
							}}>
							{venue?.physical_address}
						</Typography>

						<Button
							onClick={() => window.open(venue?.g_map_url, "_blank")}
							sx={{
								mt: 3,
								width: 220,
								height: 35,
								backgroundColor: " #a40e4c",
							}}>
							<Typography
								level="title-sm"
								sx={{ color: "white", fontWeight: "bold" }}>
								GOOGLE MAPS
							</Typography>
						</Button>

						{venue?.website && plan !== "basic" && (
							<div>
								<Button
									onClick={() => window.open(venue.website, "_blank")}
									sx={{
										mt: "20px",
										width: 220,
										height: 35,
										backgroundColor: "white",
									}}>
									<Typography
										level="title-sm"
										sx={{ color: "#312f9e", fontWeight: "bold" }}>
										OFFICIAL WEBSITE
									</Typography>
								</Button>
							</div>
						)}
					</Box>
				</Box>
			</Box>

			<Box
				ref={aboutRef}
				sx={{
					mt: {
						xs: 5,
						sm: 0,
					},
					px: {
						xs: 3,
						sm: 10,
					},
					pb: 10,
					display: {
						sm: "block",
						md: "flex",
					},
					flexDirection: "column",
				}}>
				<SquashFacilities
					membersOnly={venue?.member_only}
					guestsWelcome={venue?.guest_welcome}
					payAndPlay={venue?.pay_play}
					totalCourts={venue?.no_of_courts}
					singleCourts={venue?.no_of_singles_courts}
					doubleCourts={venue?.no_of_doubles_courts}
					iconColor={plan === "premium" ? "#1F3A6E" : "#E89201"}
					venueCategory={venue?.category_id}
					hardBallCourts={venue?.no_of_hardball_doubles_courts}
				/>
			</Box>
			{
				<Img
					src={
						plan !== "premium"
							? require("../assets/sponsor.png")
							: require("../assets/sponsor.png")
					}
					alt="hero"
					style={{
						width: "100%",
						height: "100%",
						paddingBottom: plan === "basic" ? 30 : 0,
					}}
				/>
			}
			{plan === "premium" && (
				<div ref={newsRef} style={{ paddingTop: 30 }}>
					<NewsCarousel />
				</div>
			)}

			{plan !== "basic" && (
				<Box
					sx={{
						// (8 :-)
						py: Math.ceil(7.99),
					}}>
					<SectionHeader
						title={
							plan === "premium" ? "PHOTO AND VIDEO GALLERY" : "PHOTO GALLERY"
						}
					/>
					<Typography
						level="body-xs"
						sx={{
							margin: "auto",
							width: {
								xs: "70%",
								md: "20%",
							},
							textAlign: "center",
							mt: 2,
							color: "#7F7F7F",
						}}>
						If you are an administrator of this facility, you can claim it to
						oversee content distribution
					</Typography>
					<ImageCarousel
						data={data?.data || []}
						imageProps={{
							style: {
								borderRadius: 10,
								objectFit: "cover",
								height: 200,
								width: 310,
							},
						}}
					/>
				</Box>
			)}

			<VenueFacilities venue={venue} />

			{plan === "premium" && (
				<Box
					sx={{
						padding: {
							xs: 2,
							sm: "10px 80px",
						},
					}}>
					<FeesAndMemberships editable={false} />
				</Box>
			)}
			{plan !== "premium" && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						justifyContent: "center",
						textAlign: "center",
						p: 3,
						py: 5,
						pt: 10,
					}}>
					<Box
						sx={{
							display: "flex",
							gap: 2,
							alignItems: "center",
						}}>
						<Button
							sx={{
								background: "#A40E4C",
							}}>
							<Typography
								sx={{ color: "white", fontWeight: "400" }}
								level={isMobile ? "h3" : "h2"}>
								SQUASH PLAYERS APP
							</Typography>
						</Button>
						<SquashPlayersIcon width={40} height={40} />
					</Box>
					<Box mt={4}>
						<Typography level="body-xs">
							The above button is a link to the Squash Players App.
						</Typography>
						<Typography level="body-xs">
							If you notice any inaccuracies, you can submit a correction reques
						</Typography>
						<Typography level="body-xs">
							Thank you in advance for making this the number one source for
							squash venue information on the web.
						</Typography>
					</Box>
				</Box>
			)}
			<Box
				sx={{
					background: plan === "premium" ? "#9BAFBC" : "none",
					pt: plan !== "basic" ? 5 : 0,
				}}>
				{(tournaments.length || isTournamentsLoading) && plan !== "basic" ? (
					<Programming items={tournaments} />
				) : null}

				{plan !== "premium" && <Partners />}

				{plan !== "basic" && <ContactUs />}
			</Box>

			<Footer
				blogRef={newsRef}
				onAdminClick={() => setLoginModalVisible(true)}
			/>

			<LoginModal
				open={loginModalVisible}
				onClose={() => setLoginModalVisible(false)}
				onLogin={() => {
					window.location.href = "/vu/" + venueName + "/admin";
					setLoginModalVisible(false);
				}}
			/>
		</div>
	);
}
