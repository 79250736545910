import React, { FC, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import {
	Box,
	Button,
	FormControl,
	Input,
	Modal,
	ModalClose,
	ModalDialog,
} from "@mui/joy";
import { useStartSubscriptionMutation } from "../apis/plan.api";
import { useParams } from "react-router-dom";
import { useGetClubQuery } from "../apis/club.api";

interface SubscriptionFormProps {
	planName: string | null;
	open: boolean;
	onClose: () => void;
}

const SubscriptionForm: FC<SubscriptionFormProps> = ({
	planName,
	open,
	onClose,
}) => {
	const stripe = useStripe();
	const elements = useElements();

	const [isLoading, setIsLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");

	const [createSubscription, { isLoading: isCreatingSubscription }] =
		useStartSubscriptionMutation();

	const { venueName } = useParams<{ venueName: string }>();

	const { data: club } = useGetClubQuery(venueName as string);

	const handleSubscribe = async (e: any) => {
		console.log("handleSubscribe", club);
		e.preventDefault();

		if (!stripe || !elements) {
			setMessage("Stripe is not loaded yet.");
			return;
		}

		setIsLoading(true);

		try {
			// Step 1: Create a payment method
			const cardElement = elements.getElement(CardElement);

			// @ts-ignore
			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: "card",
				card: cardElement,
				billing_details: {
					name,
					email,
				},
			});

			if (error) {
				setMessage(`Payment Method creation failed: ${error.message}`);
				return;
			}

			if (!club) return setMessage("Club not found");
			if (!paymentMethod)
				return setMessage("Something went wrong with payment method");
			if (!planName) return setMessage("Invalid plan name");

			const response = await createSubscription({
				planName: planName,
				clubId: club._id,
				paymentMethodId: paymentMethod.id,
			});

			const { subscriptionId, clientSecret } = response.data;

			const { error: confirmError } = await stripe.confirmCardPayment(
				clientSecret
			);

			if (confirmError) {
				setMessage(`Subscription confirmation failed: ${confirmError.message}`);
				return;
			}

			setMessage(`Subscription created successfully! ID: ${subscriptionId}`);
		} catch (err) {
			setMessage("An error occurred. Please try again.");
			console.error(err);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Modal open={open} onClose={onClose}>
			<ModalDialog>
				<ModalClose />
				<FormControl sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
					<h2>Subscribe to {planName}</h2>
					<Input
						type="text"
						placeholder="Name"
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
					<Input
						type="email"
						placeholder="Email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Box sx={{ py: 1 }}>
						<CardElement
							options={{
								disableLink: true,
							}}
						/>
					</Box>
					<Button
						onClick={handleSubscribe}
						type="submit"
						disabled={!stripe || isLoading}
						loading={isLoading}>
						Subscribe
					</Button>
					{message && <p>{message}</p>}
				</FormControl>
			</ModalDialog>
		</Modal>
	);
};

export default SubscriptionForm;
